/* src/App.css */
.tabs-container {
    display: flex;
    height: 100vh;
  }
  
  .tabs-container .nav {
    flex-direction: column;
    width: 200px;
  }
  
  .tab-content {
    padding-left: 20px;
    flex-grow: 1;
  }

  .operation-buttons {
    display: flex; /* Aligns the buttons in a row */
    gap: 10px; /* Adds space between buttons */
  }
  
  