.team-container {
  width: 65vw;
}

.team-header {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
}

.search-bar {
  max-width: 400px;
  flex: 0 0 auto;
}

.filter-dropdown {
  width: 200px;
  flex: 0 0 auto;
}

.userbtn {
  flex: 0 0 auto;
  margin-left: auto;
}

.userbtn:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: black;
}

.team-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.team-table th, .team-table td {
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-table th {
  background-color: #ffffff;
  color: #343a40;
  border-bottom: none;
}

.team-table td {
  border-bottom: none;
}

.team-table tbody tr:last-child td {
  border-bottom: none;
}

.team-table tr:hover td {
  background-color: #f8f9fa;
}

.pagination-controls span {
  font-size: 16px;
  color: #04310b;
  cursor: pointer;
}

.pagination-controls span:hover {
  text-decoration: underline;
}

/* Tooltip customization */
.tooltip-inner {
  background-color: black !important;  /* Black background */
  color: white !important;             /* White text */
  border: none !important;             /* No border */
  padding: 8px 12px;                   /* Add padding for better spacing */
  font-size: 14px;                     /* Adjust the font size if needed */
  border-radius: 4px;                  /* Optional: slight rounding of corners */
  box-shadow: none !important;         /* Remove any box shadow */
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border: none !important;  /* Remove arrow borders */
  background-color: black;  /* Black arrow background to match tooltip */
}


@media (max-width: 768px) {
  .team-header {
      flex-direction: column;
  }

  .filter-dropdown {
      margin-top: 10px;
      width: 100%;
  }
}
