.logo{
	width: 8vw;
	height: 10vh;
  }

.error_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}
.success_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #4db6f3;
	color: white;
	border-radius: 5px;
	text-align: center;
}



#form_container {
    max-width: 80vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column; /* Display children in column layout */
    align-items: center;
    
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
       
}





#form_container input[type="text"] {
    width: 100%;
    max-width:7vh;
    height: 9vh;
    text-align: center;
    margin: 0.5rem;
    padding: 0.5rem;

    font-size: 1.5rem;
    border: solid white 2px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 2px 2px -2px rgb(0 0 0 / 20%),
        0px 2px 3px 0px rgb(0 0 0 / 10%), 0px 1px 6px 0px rgb(0 0 0 / 10%);
}

/* Add space between input boxes and submit button */
#form_container button[type="submit"] {
    margin-top: 2.5rem; /* Adjust as needed */
    height: 9%;
    width: 50%;
    background-color: #3bb19b;
    border: none;
    color: white;
    font-size: 1.3rem;
    border-radius: 2.5rem;
    text-align: center;

}
.form_container button[type="submit"]:hover {
    background-color:  #339280; 
    
}

@media screen and (max-width: 1440px) {
    #form_container {
        width: 30%;
        height: 80vh;
        
    }
    #form_container h3{
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    #form_container input[type="text"] {
      
        padding: 0.5rem;
        margin: 0.3rem;
        width: 100%;
        max-width:7vh;
        height: 8vh;
        
    }
    #form_container button[type="submit"]{
        width: 50%;
        font-size: 1.2rem;
        text-align: center;
        height: 7%;

    }
}

@media screen and (max-width: 1025px) {
    #form_container {
        width: 40%;
      
        height: 90vh;
        
    }
    #form_container h3{
        font-size: 1.5rem;
    }

    #form_container input[type="text"] {
      padding: 0%;
      margin: 0.3rem;
        width: 100%;
        max-width:8vh;
        height: 7vh;
        
    }
    #form_container button[type="submit"]{
        width: 60%;
        font-size: 1.1rem;
        text-align: center;
        height: 7vh;
        margin-top: 1.3rem;
        

    }
}

@media screen and (max-width: 650px) {
    #form_container {
        width: 80%;

        height: 100vh;

    }
    #form_container img{

        width: 20vw;
    }
    .form_container h3{
        font-size: 1rem;
    }

    #form_container input[type="text"] {
        width: 100%; /* Adjusted width for responsiveness */
        max-width: 20vw;
        height: 8vh;
        margin: 0.3rem;
        padding: 0;
        font-size: 1rem;


    }
    #form_container button[type="submit"]{
        width: 60%;

        padding: 1rem  0;
        font-size: 1rem;
        text-align: center;


    }
    .success_msg {
        width: 100%; /* Adjusted width for mobile screens */
        max-width: none; 
        font-size: 0.8rem ;/* No maximum width for mobile screens */
    }
    .error_msg{
        width: 100%;
        max-width: none;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 768px) {
    #form_container {
        width: 50%;
        padding: 2rem;
        height: 90%;
        
    }
    #form_container img{
        margin-top: 1.5rem;
        width: 14vw;
    }
    #form_container h3{
        font-size: 1.5rem;
    }

    #form_container input[type="text"] {
        width: 100%; /* Adjusted width for responsiveness */
        max-width: 8vw;
        height: 8vh;
        margin: 0.3rem;
        padding: 0%;
    }
    #form_container button[type="submit"]{
        width: 60%;
      height: 8vh;
        padding: 1rem  0;
        font-size: 1rem;
        text-align: center;
        

    }
}
@media screen and (max-width: 650px) {
    #form_container {
        width: 80%;
        
        height: 100vh;
        
    }
    #form_container img{
        
        width: 20vw;
    }
    .form_container h3{
        font-size: 1rem;
    }

    #form_container input[type="text"] {
        width: 100%; /* Adjusted width for responsiveness */
        max-width: 20vw;
        height: 8vh;
        margin: 0.3rem;
        padding: 0;
        font-size: 1rem;

        
    }
    #form_container button[type="submit"]{
        width: 60%;
      
        padding: 1rem  0;
        font-size: 1rem;
        text-align: center;
        

    }
    .success_msg {
        width: 100%; /* Adjusted width for mobile screens */
        max-width: none; 
        font-size: 0.8rem ;/* No maximum width for mobile screens */
    }
    .error_msg{
        width: 100%;
        max-width: none;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 426px) {
    #form_container {
        width: 100%;

        height: 100vh;
        
    }
    #form_container img{
        
        width: 20vw;
    }
    .form_container h3{
        font-size: 1rem;
    }

    #form_container input[type="text"] {
        width: 100%; /* Adjusted width for responsiveness */
        max-width: 20vw;
        height: 8vh;
        margin: 0.3rem;
        padding: 0;
        font-size: 1rem;

        
    }
    #form_container button[type="submit"]{
        width: 60%;
      
        padding: 1rem  0;
        font-size: 1rem;
        text-align: center;
        

    }
    .success_msg {
        width: 100%; /* Adjusted width for mobile screens */
        max-width: none; 
        font-size: 0.8rem ;/* No maximum width for mobile screens */
    }
    .error_msg{
        width: 100%;
        max-width: none;
        font-size: 0.8rem;
    }
}