.sidebar {
  height: calc(100vh - 60px); /* Adjusted height to avoid overlap with header */
  position: fixed;
  top: 60px; /* Sidebar starts just below the header */
  background-color: white;
  color: rgb(15, 15, 15);
  width: 80px;  /* Always collapsed width */
  z-index: 999; /* Ensure it is on top of other content */
}

.top {
  background-color: white;
  color: rgb(12, 1, 1);
  height: 100%; /* Fill remaining height */
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top .logo {
  margin-top: 20px;
  background: none;
}

.top .logo img.menu-logo {
  width: 40px;  /* Adjust size of logo as needed */
  height: auto;
  background: none;
}

.top .menu-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top .menu-links li {
  margin: 15px 0;
}

.top .menu-links button {
  background: none;
  border: none;
  color: rgb(19, 18, 18);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.top .menu-links .menu-icon {
  font-size: 24px;
  margin-bottom: 5px;
  text-decoration: none;
}

.top .menu-links .menu-text {
  font-size: 12px;
  text-align: center;
}

.submenu {
  position: absolute;
  left: 80px;  /* Position submenu to the right of the collapsed sidebar */
  top: 0;
  background-color: #fff;
  color: rgb(37, 70, 37);
  height: calc(100vh - 60px); /* Full height minus the header height */
  width: 160px;
  display: none;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 998; /* Ensure it sits beneath the sidebar */
}

.submenu.open {
  display: flex;  /* Show submenu when open */
}

.submenu h5 {
  margin-top: 20px;
}

.submenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submenu ul li {
  margin: 20px 0;
}

.submenu button {
  background: none;
  border: none;
  color: black;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* button:hover {
  text-decoration: underline;
} */

.submenu a {
  text-decoration: none; /* Remove underline from links */
  color: black; /* Ensure link text is black */
}

.submenu button svg {
  margin-right: 10px; /* Space between icon and text */
}

/* If you are using FontAwesome or similar */
.submenu button .menu-icon {
  margin-right: 10px; /* Space between icon and text */
}
.menu-icon{
  color: black;
}

.navlink{
  text-decoration: none;
}
