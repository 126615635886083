/* General styling for the calendar container */
.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  justify-content: center;
  margin-left: 16vw;
}
.custom-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.toolbar-buttons {
  display: flex;
  gap: 10px;
}

.month-label {
  font-size: 18px;
  font-weight: bold;
}


.calendar-header {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
}

/* Grey out weekends */
.grey-day {
  background-color: #f0f0f0 !important;
}

.calendar-day-name,
.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border: 1px solid #ddd;
}

.calendar-day.empty {
  cursor: default;
}

.current-day {
  font-weight: bold;
}

.date-cell {
  padding: 5px;
}

.locked-day {
  position: relative;
}

.locked-day::before {
  content: '\f023'; /* Unicode for lock icon */
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 1.2em;
}

.locked-day::after {
  content: attr(data-duration); /* Display the duration */
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 0.9em;
  position: absolute;
  top: 25px;  /* Adjust this based on how it should appear */
  left: 5px;
  color: #333;  /* Color to match the design */
}




.event.approved {
  border-left: 10vw solid green; /* Approved event color */
}

.event.rejected {
  border-left: 10vw solid red; /* Rejected event color */
}
.day-content {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5px;
}