/* Style for empty state and popup */
.empty-state {
  text-align: center;
  margin: 20px 0;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 55vw;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 800px; /* Adjust width as needed */
  max-height: 100vh;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.table-borderless tbody tr td,
.table-borderless tbody tr th,
.table-borderless thead th {
  border: none;
}

.duration {
  margin-right: 0.5vw;
}

.measurement {
  margin-right: 0.5vw;
}

.task-header {
  position: relative;
  border-bottom: 1px solid #000; /* Adjust color and thickness as needed */
}

.task-header h5 {
  margin: 0; /* Remove extra margins */
  display: inline-block;
}