/* Header styles */
.header {
  background: white; /* Set header background to white */
  padding: 10px 20px;
  height: 60px; /* Standard height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Make sure the header is on top */
}


.app-name {
  color: #333; /* Dark gray color for the app name */
  font-size: 24px;
  font-weight: bold;
}

.notification-bell {
  color: #333; /* Dark gray color for the bell icon */
  margin-right: 20px;
}

/* Reduced the bell icon size */
.notification-bell svg {
  font-size: 18px;
}

.user-name {
  color: #333; /* Dark gray color for user name */
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;
}

.dropdown-menu {
  right: 0;
  left: auto;
}

.notification-dropdown {
  position: absolute;
  right: 20px;
  top: 50px;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 4px;
  padding: 10px;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

.notification-footer {
  text-align: center;
  padding: 5px;
}

.mark-all-read {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.notification-bell {
  position: relative;
}

.notification-bell .badge {
  position: absolute;
  top: -8px;
  right: -8px;
}

.clear-all {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5vw;
}
.notification-dropdown {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 35vw; /* Increased the width of the dropdown */
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.notification-item {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.notification-item.unread {
  font-weight: bold; /* Make unread notifications bold */
}

.notification-item .delete-btn {
  position: absolute;
  top: 10px;
  right: 10px; /* Align the delete (cross) button to the right */
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.notification-item .delete-btn:hover {
  color: darkred; /* Darken color on hover */
}

.notification-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.notification-footer button {
  background: none;
  border: none;
  cursor: pointer;
  
  text-decoration: underline;
}

.notification-content{
  width: 33vw;
}


