/* styles.css */
.container {
    max-width: 80%;
}

h2 {
    font-size: 24px;
    font-weight: bold;
}

button {
    height: 38px;
}

.table {
    margin-top: 20px;
}

.modal-header {
    background-color: #f7f7f7;
}

.modal-title {
    font-size: 18px;
    font-weight: bold;
}

.form-group label {
    font-weight: bold;
}

button[type="submit"] {
    margin-top: 20px;
}
Table{
    
    width: 85vw;
}
