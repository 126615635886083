#signup_container {
	width: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .logo{
	width: 8vw;
	height: 10vh;
  }
  .signup_form_container {

	border-radius: 1rem;
	border-radius: 10px 10px 10px 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
				0px 3px 4px 0px rgb(0 0 0 / 14%),
				0px 1px 8px 0px rgb(0 0 0 / 12%);
  }
  .left h1 {
	margin: 0;
	color: white;
	font-size: 35px;
	align-self: center;
	align-items: center;
  }
  .left {
	flex: 1;
	display: flex;
	height: 5vh;
	flex-direction: column;
	align-items: center;
	justify-content:center ;
	text-align: center;
	padding: 0;
	margin: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
  }
  
  

 

  .right {
	flex: 3;
	
	width: 100% ;
	max-height: 80vh;
	flex-direction: column;
	align-items: center;
	
	background-color: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	margin-top:0;
	padding-top: 1rem;
  }

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin: 0%;
	padding: 0%;
	height: auto;
}

.form_container h1 {
	font-size: 40px;
	margin-top: 0;
	margin-bottom: 1rem;
}

.input {
	outline: none;
	border: none;
	width: 370px;
	padding: 13px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.error_msg,
.success_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.success_msg {
	background-color: #4db6f3;
}

.white_btn{
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}
.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: #039941;
	color: white;
	margin-top:10px;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.green_btn {
	background-color: #039941;
	color: white;
}
.h1{
	align-items: center;
}

@media screen and (max-width: 575px) {
	.login{
		width: 100vw;
	}
}