.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

h1, h2, h3, h4 {
  color: #333;
}

.milestone-form, .milestone-list {
  margin-bottom: 40px;
}

.milestone-form input, .milestone-form textarea, .milestone-form select, .milestone-form button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.milestone-form button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  border: none;
}

.milestone-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.milestone-card ul {
  list-style: none;
  padding: 0;
}

.goal input, .goal select {
  display: inline-block;
  width: calc(33.33% - 10px);
  margin-right: 5px;
}

.goal input:last-of-type, .goal select:last-of-type {
  margin-right: 0;
}

/* Modal styling */
#modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalcontent {
  background-color: white;
  padding: 20px;
  width: 500px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.new-milestone-btn {
  margin-top: 20px;
}


@media (max-width: 768px) {
  .goal input, .goal select {
    width: 100%;
    margin: 5px 0;
  }
}

