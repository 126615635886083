/* Blogs/styles.module.css */

.blogsContainer {
  padding: 20px;
  background-color: #d0d0d0;
}
/* Add this style to your existing CSS file */
.blogContentContainer {
  max-height: 400px; /* Adjust the height as needed */
  overflow: auto;
}
