.content-container {
  transition: all 0.3s ease;
}

.coming-soon-container {
  text-align: center;
}


h1 {
  font-size: 2rem;
  color: #333;
  margin: 0;
}

p {
  font-size: 1.2rem;
  color: #666;
  margin-top: 10px;
}
