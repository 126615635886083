.clear {

    border: none;
    color: white;
    outline: none; /* Removes outline */
}

.Button {
    background-color: #26344B;
    border: none;
    outline: none; /* Removes outline */
}

/* Disable hover effect */


.Button:hover {
    background-color: #26344B; /* Keep the same as the default */
    cursor: default; /* No cursor change */
}

/* Remove blue outline on focus/active */
.clear:focus, .clear:active {
    outline: none; /* Removes focus outline */
    box-shadow: none; /* Removes any box shadow */
}

.Button:focus, .Button:active {
    outline: none; /* Removes focus outline */
    box-shadow: none; /* Removes any box shadow */
}
