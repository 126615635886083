.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	background-color: #f0f0f0;
  }
  
  .logoContainer {
	width: auto;
	height: auto;
	
  }
  
  .logo {
	width: 7vw;
	height: 10vh;
	
  }
  .heading{
	margin: auto;
	transform: translate(-20%);
	
  }
  .heading h1{
	margin: 0;
  }
.container {
	width: 100vw;
	
	display: flex;
	text-align: center;
	justify-content: center;
}

.form_container {
	width: 400px;
	padding: 10px;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin-top: 10rem;
	align-items: center;
	
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.input {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: #3bb19b;
	color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	margin: 10px auto;
}

.error_msg,
.success_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.success_msg {
	background-color: #5cdd5c;
}
/* Responsive Design */
