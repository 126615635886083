table{
    
    width: 100%;
}
th{
    border: none;
    text-align: left;
    padding: 8px;
}
td{
    border: none;
    text-align: left;
    padding: 8px;
}
tr{
    border-bottom: none;
}


.col-md-3 {
    width: 10vw; /* Adjust sidebar width */
    float: left; /* Ensure sidebar floats left */
}

.col-md-9 {
     
    float: left; /* Ensure content floats left */
}