:root {
	font-size: 16px; /* Set base font size */
  }
  
  .logo{
	width: 8vw;
	height: 10vh;
  }
  .login_form_container {
	width: 35rem; /* Converted from 35vw */
	max-width: 100vw;
	height: 89vh;
	margin: 0;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	border-radius: 1rem; /* Converted from 10px */
	box-shadow: 0px 0.1875rem 0.1875rem -0.125rem rgba(0, 0, 0, 0.2),
	  0px 0.1875rem 0.25rem 0px rgba(0, 0, 0, 0.14),
	  0px 0.0625rem 0.5rem 0px rgba(0, 0, 0, 0.12);
  }
  
  #loginHeading{
	margin-top: 2rem;
	font-size: 2rem;
	font-weight:500;
  }

  .form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
	width: auto;
  }
  
  

 #forgetPass{
	
	margin-top: 0.5rem;
	padding: 0 15px ;
	
 }
  
 
  
  #input {
	outline: none;
	border: none;
	width: 100%; /* Converted from 370px */
	padding: 0.9375rem; /* Converted from 15px */
	border-radius: 1rem; /* Converted from 10px */
	background-color: #edf5f3;
	margin: 0.3125rem 0; /* Converted from 5px */
	font-size: 0.875rem; /* Converted from 14px */
	margin: 0.3rem;
  }
  #Checkbox{
		
		font-size: 1rem;
		margin-top:0.5rem;

  }


  
  .error_msg {
	width: 23.125rem; /* Converted from 370px */
	padding: 0.9375rem; /* Converted from 15px */
	margin: 0.3125rem 0; /* Converted from 5px */
	font-size: 0.875rem; /* Converted from 14px */
	background-color: #f34646;
	color: white;
	border-radius: 0.3125rem; /* Converted from 5px */
	text-align: center;
	
  }
  
  #right {
    flex: 1;
    display: flex;
    height: 2.5rem; /* Converted from 10vh */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #4db6f3;
    border-bottom-left-radius: 1rem; /* Converted from 10px */
    border-bottom-right-radius: 1rem; /* Converted from 10px */
}

#right h1 {
    margin-top: 0;
    color: white;
    font-size: 1.5rem; /* Converted from 24px */
    align-self: center;
}



  
  .white_btn{
	border: none;
	outline: none;
	padding: 0.75rem 0; /* Converted from 12px */
	background-color: white;
	border-radius: 1.25rem; /* Converted from 20px */
	width: 11.25rem; /* Converted from 180px */
	font-weight: bold;
	font-size: 0.875rem; /* Converted from 14px */
	cursor: pointer;
	margin: 0.625rem; /* Converted from 10px */
color:black;
  }
  #green_btn {
	border: none;
	outline: none;
	padding: 0.75rem 0; /* Converted from 12px */
	background-color: #3bb19b;
	color: white;
	
	border-radius: 1.25rem; /* Converted from 20px */
	width: 11.25rem; /* Converted from 180px */
	font-weight: bold;
	font-size: 0.875rem; /* Converted from 14px */
	cursor: pointer;
	
  }

  @media screen and (max-width: 769px) {
	/* Update the login form container width */
	.login_form_container {
	  
	  height:90vh
	}
	.logo{
		height:14vh
	}
	


}