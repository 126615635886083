.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .not-found-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .not-found-404 {
    font-size: 10rem;
    color: rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  
  .not-found-content h2 {
    font-size: 2rem;
    margin: 20px 0;
  }
  
  .not-found-content p {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .home-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .home-button:hover {
    background-color: #0056b3;
  }
  