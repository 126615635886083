
.side1{
  display: flex;
  margin-left: 0;
}
.side2{
  display: flex;
  margin-right: 0;
}
.navbar{
   height: 70px;
   width: 90vw;
   display: flex;
   margin-left: 0;
   justify-content: space-between;

   color: #26344B;
   /* column-gap:70vw; */
}
.navbar-logo{
   margin: 0px;
   padding: 3px;
   font-size: 30px;
   color: #26344B;
}
.navbar-menu ul{
   display: flex;
   list-style-type: none;
}
.navbar-menu a{
   color: #26344B;
   margin: 4px;
   text-decoration: none;
   font-size: 20px;
}
.navbar-user img{
   margin-top: 0;
   height: 40px;
   border-radius: 50%;
   display: flex;
   justify-content: right;
   align-self: flex-end;
}
.logo img{
   margin: 5px;
   height: 40px;
   border-radius:5px ;
}
.navbar-user{
   width: 50px;
   height: 40px;
   padding: 0;
}
.navbar-logo:hover {
   text-decoration: underline;
}
.navbar-menu:hover{
   text-decoration: underline;
}
.navbar-user a{
   margin: 0%;
}
.bell i{
 height: 100px;
}
.pf{
   width: 300px;
  margin: 0;
   background-color: none;
}
.pf h1{
   text-align: center;
}
.pf p{
   text-align: center;
}
.btn button{
   width: 200px;
  display:flex;
  flex-direction: column;
  font-size: larger;
  text-align: center;
  background-color:white;
  color:#26344B;
}
.logo{
   height: 6vh;
   border: none;
   margin-top: 4px;
}
.logo img{
   height: 5vh;
   margin-top: 0px;
}
.side1 Button{
   margin: 0px;
   width: 7vw;
   margin-left: 2px;
}
.side1 Button:hover{
   background-color: #A8B1BF;
}
.profile.c{
   width: 170px;
   height: 200px;
   border: 5px solid black;
   justify-content: center;
   background-color: rgb(210, 213, 216);
}
.profile-image{
   display: flex;
   justify-content: center;
   align-items: center;
}
.profile-image img{
   width: 65px;
   height: 65px;
   margin-left: 0;

}
.profile{
   width: 100px;
   height: 100px;
}
.profile-container{
   width: 200px;
   height: 200px;
   display: flex;
   flex-direction: column;
   align-content: flex-end;
}
.profile-icon{
   height: 28px;
   width: 28px;
   margin-right: 5px;
   margin-bottom: 10px;
}
.preference-icon{
   height: 28px;
   width: 28px;
   margin-right: 5px;
   margin-bottom: 10px;
}
.logout-icon{
   height: 28px;
   width: 28px;
   margin-right: 5px;
}
.profile.c{
   display: none;
}
.profile-c{
   margin-right: 20px;
   margin: 0;
}

.bell{
   height: 35px;
   width: 35px;
}
.icon{
   margin-right: 10px;
}
.icon button{

   color: #26344B;
   border: none;
}
.noti{
   width: 200px;
   height: 200px;
   color: #364865;
}
.tith{
   height: 35px;
   width: 35px;
}
.menu{
   margin: 0px;
}
.menu button{
   border: none;

   color: #26344B;
}
.btn-Model{
   border: none;
   border-radius: 50%;

   margin-bottom: 10px;
}
.btn-Model button{
   margin-bottom: 10px;
   width: 45px;
   height: 45px;
}
.btn-Model img{
   width: 45px;
   height: 45px;
   margin-top: -5px;
}
.btn{
 display: flex;
 justify-content: center;
   margin-top: 10px;
}
.button1
{
   display: flex;
   justify-content: center;
   margin-top: 3px;
   margin-right: 3px;
}
.button2
{
   display: flex;
   justify-content: center;
   margin-top: 3px;
   margin-right: 3px;
}
.button3
{
   display: flex;
   justify-content: center;
   margin-top: 3px;
   margin-right: 3px;
}
.que {
   height: 35px;
   width: 35px;
   margin-right: 10px;

   color: #26344B;
}
.question{

   border: none;
}
.btn button{
   background-color: #F0F3FA;
   color: #26344B;
}
.btn button{
   border: none;
   background-color: white;
}
.menu button{

   border:1px solid #26344B;
   width: 30px;
   height: 30px;
   color: #26344B;
}
.menu1 button{
   border:1px solid #26344B;
   width: 30px;
   height: 30px;
   margin-left: 4vw;
   color: #26344B;
}
.list1 li:hover{
   background-color:rgb(186, 182, 182);
}
.list2 li:hover{
   background-color: rgb(186, 182, 182);
}
.menu{

   border:1px solid #26344B;
   width: 30px;
   color: #26344B;
   margin-left: 9vw;
}
#newsidebar{

   position: fixed;
   top: 0;
   width: 5vw;
   height: 100%;
   margin-top: 70px;
   color: #26344B;
}
#sidebar.closed{
   transform: translateX(-10px);
}
.dashboard{
   width: 25px;
   height: 25px;
}
#newsidebar ul{
   list-style-type: none;
   padding: 0;
   text-align: center;
}
.list2 ul{
   list-style-type: none;
}

#sidebar{
   width: 10vw;

   height:100vh;
   position: fixed;
   left: 0;
   color: #26344B;
   transition-property: transform;
   transition-duration: 0.3s;
   transition-timing-function: ease-in;
   transition-delay: 0s;
}
#sidebar ul{
   list-style-type: none;
   padding: 0;
}
#sidebar li{
   padding: 15px;
   text-align: center;
}
#sidebar a{
   color: #26344B;
   text-decoration: none;
   display: block;
   font-size: 20px;
}
.profile-name input{
     width: 300px;
     border: none;
     text-align: center;
     font-size: 20px;
}
.markasread{
   width: 10vw;
   margin-top: 64vh;
   margin-left: 12vw;
   background-color: #26344B;
   color: white;
}
@media screen and (max-width: 3000px) {
   .navbar {
       height: 70px;
       width: 100vw;
       display: flex;
       justify-content: space-between;
       flex-direction: row;
       color: #26344B;
       /* column-gap:70vw; */
    }
   .navbar-logo {
      margin: 5px;
      padding: 3px;
      font-size: 25px;
    }
   .navbar-menu {
      display: flex;
      justify-content: space-between;
    }
   .navbar-menu ul {
      display: flex;
      list-style-type: none;
    }
   .navbar-menu a {
      color: #26344B;
      margin: 4px;
      text-decoration: none;
      font-size: 20px;
    }
   .navbar-user {
      padding: 0;
    }
   .navbar-user img {
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: right;
      align-self: flex-end;
    }
    .icon{
       margin-right:7px;
   }
   .side1{
     display: flex;
     margin-left: 0;
  }
  .side2{
     display: flex;
     margin-right: 0;
  }
  }
  @media screen and (max-width: 1500px) {
     .navbar {
       height: 70px;
       width: 100vw;
       display: flex;
       justify-content: space-between;
       flex-direction: row;
       color: #26344B;
      }
     .navbar-logo {
        margin: 5px;
        padding: 3px;
        font-size: 25px;
      }
     .navbar-menu {
        display: flex;
        justify-content: space-between;
      }
     .navbar-menu ul {
        display: flex;
        list-style-type: none;
      }
     .navbar-menu a {
        color: #26344B;
        margin: 4px;
        text-decoration: none;
        font-size: 20px;
      }
     .navbar-user {
        padding: 0;
      }
     .navbar-user img {
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: right;
        align-self: flex-end;
      }
      .icon{
         margin-right:7px;
     }
     .side1{
       display: flex;
       margin-left: 0;
    }
    .side2{
       display: flex;
       margin-right: 0;
    }
    }
    .notification-dropdown {
      position: absolute;
      top: 60px;
      right: 20px;
      background-color: white;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      width: 300px; /* Adjust width as needed */
      border-radius: 4px;
      padding: 10px; /* Optional padding */
    }
    
    .notification-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; /* Space between header and notifications */
    }
    
    .notification-button {
      cursor: pointer;
      padding: 10px 15px;
      margin: 0 5px;
      background-color: #007bff;
      color: white;
      border-radius: 4px;
      text-align: center;
      transition: background-color 0.3s, transform 0.2s;
    }
    
    .notification-button:hover {
      background-color: #0056b3;
      transform: scale(1.05); /* Slightly enlarge on hover */
    }
    
    .notification-item {
      padding: 10px;
      border-bottom: 1px solid #f0f0f0;
    }
    
    .notification-item:last-child {
      border-bottom: none; /* Remove border for the last item */
    }
    
    .read {
      color: gray; /* Optional styling for read notifications */
    }
    
    .unread {
      font-weight: bold; /* Emphasize unread notifications */
    }
    
    
