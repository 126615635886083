h1{
  margin:10px;
  margin-top:5px;
  margin-bottom: 10px;
}


h6{
  text-decoration: none;
  margin: 10px;
  margin-left:0px;
}
.team-container
{
  margin-top:30px; 
  margin-left: none;
}
.tabs
{
  display:flex;   

}
#tab1:hover
{
text-decoration-line: underline;

}
#tab2:hover
{

  text-decoration-line: underline;

}
#tab3:hover
{

  text-decoration-line: underline;

}
#tab1
{
  margin:10px;
  padding:3px;
  

}
#tab2
{
  margin:10px;
  padding:3px;

}
#tab3
{
  margin:10px;
  padding:3px;

}

#addbutton
{
  background-color:#26344B;
  color:white;

  
}
.groupdiv 
{
  
  padding-top:10px;
  padding-bottom:10px;
  gap:1rem;

}
.groupdiv button{
  background-color:#26344B;
  color:#fff;
  border-radius: 3px;
  cursor:pointer;
}
#add-member
{

 background-color:#26344B;
  color:#fff;
  border-radius: 5px;
  cursor:pointer;
  margin-top:7px;


}
.search-bar input
{
  width:400px;
  border-bottom-right-radius:5px;
  border-top-right-radius:5px;
  margin-top:10px;
  height:38px;
  border:1px solid #69696b;
}
.magnifying-glass
{
  border-radius:5px;
  border:1px solid #69696b;
 font-size:2.40rem;
 margin-left:30px;
 padding:4px;
 margin-bottom:5px;
   border-bottom-right-radius:0px;
 border-top-right-radius:0px;

}
.show-all
{
  margin:20px;
  margin-left: 0px;
 

}
.addmembtn
{
  display:flex;
  align-items: self-end;

}
.search-bar 
{
  display: flex;
 
  padding-bottom:5px;
  margin-bottom:10px;
}
.searchadd
{
  display:flex;
  justify-content:space-between;
}

#dropdown-basic
{
  background-color: #dbdeef;
  border:none;
  color:#50525c;
}
#dropdown-show
{
  background-color: #ffffff;
  border:none;
  color:#50525c;
}
.member-container
{
  padding:0px;

}
.cross
{
  display:flex;
  justify-content: space-between;
}
.Model button{
  background-color:#26344B;
  color:#fff;
  border-radius: 3px;
  cursor:pointer;
  margin:10px;
}
.invitation
{
  display:flex;
  height:40px;
  margin:10px;
  margin-left: 0px;
  border-bottom:1px solid #666;
  margin-top:15px;
  padding:5px;
}
.Model{
  margin:10px;
  width:100%;

}


.addnewgroup{
  display:flex;
  justify-content:right;
  padding-top:10px;
  margin-bottom:10px;
}
#addnewgroup
{

  width:200px;
  margin-right: 15px;
}
.addnewgroup button
{
  background-color:#26344B;
  color:#fff;
  border-radius: 3px;
  cursor:pointer;
  height: 30px;
}

.content
{
  display:none;
}
.show-content
{
  display:block;
  background-color: #ffffff;
  padding-top:0px;
  padding:22px;
  padding-left:5px;
  padding-right:40px;
  
}
.members-table {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 3px;
}

.table-header {
  color:#777985;
 
}

th {
 
  text-align: left;


}
tr{
  border-top: 1px solid #ccc;
}
.memdivheading
{
  display:flex;
  justify-content:space-between ;
  background-color: #dbdeef;
  padding-left:10px;
 
  border:#c7cdee;
  font-style: none;
  font-size:14px;
  color:#50525c;
  width:auto;
  
  
}
.fas {
  margin-left: 5px;
}
.table-cell1 {
  padding: 10px;
  text-align: left;
 
}
.table-header1 {
  
  color:#777985;
}

.menuactive button{
  background:none;
  border:none;
}
.dropdown-menu-options
{
  
  background-color: #ffffff;
position:absolute;
box-shadow:2px 5px 5px black;
border-radius: 5px;

}
.dropdown-menu-option1
{
  display:flex;
  justify-content: center;
  border:none;
  background-color: #ffffff;
  padding-left:18px;
}
.dropdown-menu-option2
{
  display:flex;
  border:none;
  background-color: #ffffff;
  justify-content: center;
  border-radius: 5px;
}
.dropdown-menu-option1:hover{
  background-color:#dbdeef;
}
.dropdown-menu-option2:hover{
  background-color:#dbdeef;
}
.team-container{
  margin-left: 1vw;
  width:88vw;
}


.group-button{
  border: none;
  background-color: white;
}
.dropdown-menu-group
{
  position:absolute;
  background-color: #ffffff;
box-shadow:2px 3px 3px black;
border-radius: 5px;
margin-left:65%;
padding:12px;

}
.dropdown-menu-group1{
 
  border-radius: 5px;
  border:1px solid #ccc;
}
th ,td
{
 border: 1px solid #ddd;

}
.Modal3 {
  display: flex !important;
  align-items: center;
  justify-content: center;

}
.btngroupdivmodal{
  background-color:#26344B;
  color:#fff;
  border-radius: 3px;
  cursor:pointer;
}
.Modal3 .modal-dialog {
  margin: 0;
}
#selectuserdd 
{
  background-color: #fafafa;
  border:none;
  color:#50525c;
}

