.profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; /* Optional: for a light background */
  }
  
 
  .main {
    height: 500px;
    width: 70vw;
    margin-top: 10px;
    
    border: 3px solid #dbdeef;
    background-color: #dbdeef;
}

.profile {
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 12vw;
    width: 80vw;
    height: 600px;
    justify-content: center;
    align-items: center;
    
}

.middle {
    margin-left: 17px;
    display: flex;
}

/* .image img {
    margin: 0px;
    margin-left: 5px;
    height: 200px;
    width: 200px;
} */
.profile-avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }

.heading {
    margin-left: 4px;
}

.btns {
    margin: 5px;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #dbdeef;
}

.profile h1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
}

.btn2 {
    height: 40px;
    width: 200px;
    margin-left: 12px;
    text-align: center;
    border: none;
}

.div {
    margin-left: 3px;
    display: flex;
}

.pass button {
    margin-left: 3px;
}

.personal {
    margin-left: 3px;
}

.pass {
    margin-left: 3px;
}

.div1 {
    margin-left: 15px;
    width: 50%;
}

.div1 input {
    width: 95%;
}

.para1 {
    font-size: 25px;
}

.para2 {
    font-size: 25px;
}

.email {
    width: auto;
    height: 29.5px;
    border: 2px thin black;
    padding: 5px;
}

.btn1 {
    margin-left: 3px;
    width: 180px;
    height: 42px;
}

.pass button {
    background-color: #26344B;
    color: #f0f3fa;
}

.div2 button {
    background-color: #26344B;
    color: #f0f3fa;
}

.div2 input {
    width: 440px;
}

.btns img {
    border: 3px solid #fd6200;
}

.profile h1 {
    color: #fd6200;
}

.btns Button {
    background-color: #26344B;
    color: #f0f3fa;
}

/* .profile .main .btns img,
.profile .main .btns .react-avatar--image {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border: 2px solid orange; 
} */

.profile .main .btns {
    text-align: center;
}

.profile .main .btns .btn1,
.profile .main .btns .btn2 {
    margin-top: 10px;
}

.profile .main .personal,
.profile .main .div,
.profile .main .pass {
    margin-top: 20px;
}
.btn1,
.btn2 {
  background: none;
  border: 1px solid #dbdeef; /* Adjust the color if needed */
  color: black; /* Make the icons black */
  padding: 5px; /* Adjust the padding as needed */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px; /* Optional: Add border radius for rounded corners */
}