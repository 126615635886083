/*.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
*/
.inputField {
  margin-bottom:1rem; /* Adjust the margin as needed */
}




/* Rest of the CSS code */

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input,
.textarea {
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 48%;
  transition: transform 0.2s;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.1);
}

.inputemail,
.textareamsg {
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: transform 0.2s;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.1);
}

.textareamsg {
  height: 10rem;
}

.button {
  width: 15vw;
  padding: 0.5rem 2rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  margin-left: 33%;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.button:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

.input:focus,
.textarea:focus {
  transform: scale(1.05);
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

.inputemail:focus,
.textareamsg:focus {
  transform: scale(1.05);
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

.spacer {
  flex-basis: 2%;
}

.input::placeholder,
.textarea::placeholder {
  text-align: left;
}

.error_msg,
.success_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
  margin-left: 16.5%;
}

.success_msg {
	background-color: #4db6f3;
}

/* Media Queries for Responsive Design */
@media screen and (min-width: 768px) {
  .form {
    width: 50%; /* Adjusted width for larger screens */
  }

  .row {
    flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  }

  .input,
  .textarea {
    width: 48%; /* Adjusted width for larger screens */
  }

  .spacer {
    flex-basis: 2%;
  }

  .error_msg,
  .success_msg {
    width: 370px; /* Adjusted width for larger screens */
    margin-left: auto; /* Centering the message */
    margin-right: auto; /* Centering the message */
  }
}