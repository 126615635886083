  .container {
    margin: 20px auto;
    padding: 20px;
    max-width: 100vw;
    
  }
  
  .info-section {
    padding: 10px 20px;
    border-radius: 0.5rem;
    margin-bottom: 10px;
  }
  
  .info-section strong {
    font-weight: bold;
  }
  
  .table {
    margin-top: 20px;
    background-color: white;
    border-collapse: collapse;
    width: 100%;
  }
  
  .table th,
  .table td {
    text-align: center;
    padding: 8px;
    border: 1px solid #dee2e6;
  }
  
  .table th {
    background-color: grey;
    color: white;
  }
  
  .next-week-plan {
    margin-top: 20px;
  }
  
  .text-area textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .status-dropdown {
    width: 10vw;  /* Adjust this as needed for dropdown width */
  }
  @media print {
    /* For PDF export, remove margins and ensure single-page layout */
    body {
      background-color: white;
      -webkit-print-color-adjust: exact;
    }
    
    .container {
      margin: 0;
      padding: 0;
      box-shadow: none;
      width: 100%;
    }
    
    .table th {
      background-color: #007bff !important; /* Ensure colors are maintained when printing */
    }
    
    .page-break {
      page-break-before: always;
    }
  }
  
